<!--
 * @Description: 添加任务项
 * @Author: ChenXueLin
 * @Date: 2021-08-30 15:10:25
 * @LastEditTime: 2022-07-15 16:19:41
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="添加任务项"
    :visible="addTaskItem"
    width="900px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="DialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="add-task-dialog"
  >
    <el-form :inline="true" ref="invoiceApplySearch" class="searchForm">
      <el-form-item class="search-item--1" prop="corpId">
        <el-input title="客户名称" placeholder="客户名称"></el-input>
      </el-form-item>
      <el-form-item class="search-item--1" prop="corpId">
        <el-input title="联系人" placeholder="联系人"></el-input>
      </el-form-item>
      <el-form-item class="search-item--1" prop="corpId">
        <el-input title="联系电话" placeholder="联系电话"></el-input>
      </el-form-item>

      <el-form-item class="search-item--1" prop="billingStatus">
        <e6-vr-select
          :data="typeList"
          title="单据类型"
          placeholder="单据类型"
          v-model="typeId"
        ></e6-vr-select>
      </el-form-item>

      <el-form-item class="search-item--2" prop="applyTime">
        <!--   v-model="invoiceApplyForm.applyTime"  :picker-options="pickerOptions" -->
        <e6-date-picker
          popper-class="no-atTheMoment"
          label="创建时间"
          ref="applyTimeDatePicker"
          type="date"
          title="创建时间"
          :picker-options="pickerOptions('searchForm.finishTime')"
          :default-time="['00:00:00', '23:59:59']"
          start-placeholder="创建时间（始）"
          end-placeholder="创建时间（止）"
        ></e6-date-picker>
      </el-form-item>

      <el-form-item class="search-item--buttons">
        <el-button type="primary">查询</el-button>
        <el-button class="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table border height="300px" highlight-current-row>
      <el-table-column
        label="序号"
        type="index"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
      </el-table-column>
    </el-table>
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel">取消</el-button>
      <el-button type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "",
  components: {},
  data() {
    return {
      searchForm: {},
      DialogLoading: false,
      typeId: "",
      value: "",
      total: 0,
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ],
      columnData: [
        {
          fieldName: "billHeadeName",
          display: true,
          fieldLabel: "客户",
          width: 130,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "billNo",
          display: true,
          fieldLabel: "联系人",
          width: 110,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "billTypeCodeName",
          display: true,
          fieldLabel: "联系电话",
          width: 125,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "billMoney",
          display: true,
          fieldLabel: "单据号",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "billBalance",
          display: true,
          fieldLabel: "单据类型",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "billDate",
          display: true,
          fieldLabel: "回访状态",
          width: 110,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "bindMoney",
          display: true,
          fieldLabel: "创建时间",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      bindAmountPos: [] // 绑定金额编辑的行列
    };
  },
  props: {
    addTaskItem: {
      type: Boolean,
      default: false
    }
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  methods: {
    handleClose() {
      this.addService = false;
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.searchForm {
  padding-top: 10px;
  padding-left: 10px;
}
</style>
